.ReactImagePickerEditor .place-image .image-holder-loaded {
    flex-direction: column;
    display: contents !important;
    place-content: center;
    align-items: center;
    position: relative;
    max-width: 0% !important;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 2px;
}

.ReactImagePickerEditor .curtain {
    position: static !important;
    top: 0;
    display: grid;
    place-items: center;
    left: 0;
    height: 0% !important;
    width: 0% !important ;
    background-color: transparent;
    transition: background-color .5s ease;
    cursor: pointer;
}