.container {
    position: relative;
}

.container img {
    display: block;
}

.container .fa-times {
    position: absolute;
        bottom: 80%;
        left: 60%;
        color: white;
        font-size: 14px;
}