.wrapper {
  position:relative;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  /* background-color: red; */
  /* padding: 4px; */
  position: absolute;
  box-sizing:border-box;
  top:50%;
  left:80%;
  transform: translateY(-50%);
}

.input {
  height: 50px;
  box-sizing:border-box;
  padding-left: 1.5rem;
}